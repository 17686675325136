import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getListService, } from 'services/portal.service'
import Cookies from 'utils/cookies'
import GroupApp from './component/GroupApp'
import MiniApp from './component/MiniApp'
import { TYPE_APP } from 'constants/common'
import { Popup } from './Popup'

const BoxWrapper = styled('div')(() => {
  return {
    paddingBottom: '52px',
    '& p, & h3': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
    },

    '& .btn-edit': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      color: '#2560E5',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      padding: 0,
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
    '& .btn-edit-small': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      color: '#2560E5',
      fontWeight: '400',
      fontSize: '11px',
      lineHeight: '12px',
      padding: 0,
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
  }
})
function ListApp(props) {
  const userInfo = useSelector((state) => state.user.user)
  const token = Cookies.getToken()
  const [miniApp, setMiniApp] = useState([])
  const [dataDrawer, setDataDrawer] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)
  const [open, setOpen] = useState(true)


 
  const isSuperAdmin = () => {
    return userInfo?.is_admin
  }


  const fetchDataService = async () => {

    const services = await getListService();
    
    const filteredServices = services.filter(service => {
      return isSuperAdmin() || service?.public || Object.keys(userInfo?.permission_services ?? {}).includes(service?.slug);  
    });
    const sortedServices = sortBy(filteredServices, 'sort');
  
    setMiniApp(sortedServices);
  
  };

  useEffect(() => {
    fetchDataService()
  }, [])
  const matches = useMediaQuery(`(max-width:1600px)`)
  const matchesMobile = useMediaQuery(`(max-width:768px)`)

  return (
    <BoxWrapper>
      {/* <Popup open={open} setOpen={setOpen}/> */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={(theme) => ({
          height: '100%',
        })}
      >
        <Grid item xs={12}>
          <Box className="w-full flex flex-col items-center">
            <Box sx={{
              '& p:first-child': {
                color: '#C92127',
                fontWeight: '700',
                fontSize: matchesMobile ? "24px" : '32px',
                lineHeight: matchesMobile ?"28px" : '40px'
              },
              '& p:last-child': {
                color: '#737373',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '400',
                mt: '8px',
                textAlign:'center'
              },
              margin: matchesMobile ? "32px 0": '64px 0'
            }}>
              <Typography>
                FORLIFE HOME
              </Typography>
              <Typography sx={{
                
              }}>
              Luôn nghĩ cho khách hàng
              </Typography>
            </Box>

            <Box
              className={`w-full grid ${matchesMobile ? 'gap-2' :
                matches ? 'gap-4 gap-y-4' : 'gap-4'
                } px-4 justify-center 2xl:grid-cols-8 xl:grid-cols-8 md:grid-cols-6 sm:grid-cols-4 grid-cols-2`}
              sx={
                matches ? {width: "100%"}: {maxWidth: 1600}
              }
            >
              {miniApp.map((item, index) => {
                if (item?.status && (item?.type?.toString() === TYPE_APP.ALL || item?.type?.toString() === TYPE_APP.WEB))
                  if (item.is_group) {
                    return <Box key={item.id} onClick={() => {
                      setOpenDrawer(true)
                      setDataDrawer(item)
                    }}>
                      <MiniApp
                        data={item}
                        key={index.toString()}
                        description={item?.description}
                        appName={item?.name}
                        href={null}
                        logo={item?.logo}
                      />
                    </Box>
                  }
                  else return (
                    <MiniApp
                      key={index.toString()}
                      description={item?.description}
                      appName={item?.name}
                      href={`${item.url}/?token=${token}`}
                      logo={item?.logo}
                    />
                  )
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {openDrawer && <GroupApp openDrawer={openDrawer} handleClose={() => setOpenDrawer(false)} dataDrawer={dataDrawer} />}

    </BoxWrapper>
  )
}

export default ListApp
